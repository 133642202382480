import React from 'react';
// import { useEffect, useState } from 'react';
import './App.css';

function App() {
  // const [date, setDate] = useState(null);
  // useEffect(() => {
  //   async function getDate() {
  //     const res = await fetch('/api/date');
  //     const newDate = await res.text();
  //     setDate(newDate);
  //   }
  //   getDate();
  // }, []);
  return (
    <main>
      <h1>Ulugnor Group</h1>
      <p>Coming Soon</p>
      <a href="https://t.me/UlugnorGroup">&copy; Ulugnor Group</a>
    </main>
  );
}

export default App;
